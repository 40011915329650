import React from 'react'

import QuestionText from './question-text.js'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


/**
 * Renders a form control as textarea with configurable rows
 *
 * @class Textarea
 * @extends {React.Component}
 */
class Textarea extends React.Component {
    render() {
        const props = this.props
        const field = props.field

        return (
            <Form.Group as={Row} controlId={field.id}>
                <QuestionText field={field} column={true} sm={3}/>
                <Col sm="9">
                    <Form.Control
                        as="textarea"
                        rows={props.rows}
                        className={props.className}
                        value={props.values[field.id]}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        isValid={props.touched[field.id] && !props.errors[field.id]}
                        isInvalid={props.touched[field.id] && !!props.errors[field.id]}
                    />
                    <Form.Control.Feedback type="invalid">
                        {props.errors[field.id]}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
        )
    }    
}

export default Textarea