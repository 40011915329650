import React from 'react'
import { ErrorMessage } from 'formik'
import QuestionText from './question-text.js'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

/**
 * Renders checkbox input
 *
 * @class Check
 * @extends {React.Component}
 */
class Check extends React.Component {
    render() {
        const props = this.props
        const field = props.field
        const values = field.question_values.split('\n')

        // render a single checkbox
        if (values.length <= 1) {
            let value = values[0]
            return (
                <Form.Group as={Row} controlId={field.id}>
                    <QuestionText field={field} column={true} sm={3}/>
                    <Col sm="9">
                        <Form.Check
                            type="checkbox"
                            label={value}
                            name={field.id}
                            id={field.id}
                            className={props.className}
                            value={value}
                            onChange={event => {
                                let target = event.currentTarget
                                
                                // For checkbox fields without a question value return a boolean
                                if (value === "") {
                                    props.setFieldValue(field.id, target.checked)
                                    return
                                }
                                
                                // otherwise use the checkbox value when checked, or an empty string when unchecked
                                props.setFieldValue(field.id, target.checked ? target.value : '')
                            }}
                            onBlur={props.onBlur}
                            isValid={props.touched[field.id] && !props.errors[field.id]}
                            isInvalid={props.touched[field.id] && !!props.errors[field.id]}
                            feedback={props.errors[field.id]}
                        />
                    </Col>
                </Form.Group>
            )
        }

        // render a group of checkboxes
        if (values.length > 1) {
            return (
                <Form.Group as={Row} controlId={field.id}>
                    <QuestionText field={field} column={true} sm={3}/>
                    <Col sm="9">
                        {values.map((value, key) => { 
                            return (
                                <Form.Check
                                    type="checkbox"
                                    name={field.id}
                                    id={field.id + '-' + key}
                                    label={value}
                                    value={value}
                                    key={key}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                    isValid={props.touched[field.id] && !props.errors[field.id]}
                                    isInvalid={props.touched[field.id] && !!props.errors[field.id]}
                                />
                            )
                        })}
                        <div className="invalid-feedback" style={{ display: 'block', paddingLeft: '1.25rem' }}>
                            <ErrorMessage name={field.id}/>
                        </div>
                    </Col>
                </Form.Group>
            )
        }
    }    
}

export default Check