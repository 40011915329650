import React from 'react'

import QuestionText from './question-text.js'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


/**
 * Renders a generic form control with configurable type
 * Allowing for text input and file upload fields
 *
 * @class Input
 * @extends {React.Component}
 */
class Input extends React.Component {
    render() {
        const props = this.props
        const field = props.field
        const isHidden = field.is_hidden
        
        return (
            <Form.Group as={Row} controlId={field.id} className={isHidden ? 'd-none' : ''}>
                <QuestionText field={field} column={true} sm={3}/>
                <Col sm="9">
                    <Form.Control
                        type={props.type}
                        min={props.min}
                        max={props.max}
                        name={field.id}
                        className={props.className}
                        value={props.values[field.id]}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        isValid={props.touched[field.id] && !props.errors[field.id]}
                        isInvalid={props.touched[field.id] && !!props.errors[field.id]}
                    />
                    <Form.Control.Feedback type="invalid">
                        {props.errors[field.id]}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
        )
    }    
}

export default Input