import React from 'react'

import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Form from 'react-bootstrap/Form'

import { Formik, Field } from 'formik'
import _ from 'underscore'

import properties from '../properties'

class PaymentForm extends React.Component {
    constructor() {
        super()

        this.countDecimals = this.countDecimals.bind(this)
        this.exceedsBalance = this.exceedsBalance.bind(this)
        this.validatePayment = this.validatePayment.bind(this)
    }

    // https://stackoverflow.com/questions/17369098/simplest-way-of-getting-the-number-of-decimals-in-a-number-in-javascript
    countDecimals(value) {
        if (Math.floor(value) === value) return 0
        return value.toString().split(".")[1].length || 0;
    }

    exceedsBalance(value) {
        if (this.props.data.request_amount_greater_than_balance) {
            return false
        }

        return parseFloat(value) > parseFloat(this.props.processor.AMOUNT)
    }

    validatePayment(value) {
        // empty
        if (!value) {
            return properties.paymentInvalid
        }

        // not a number
        if (isNaN(value)) {
            return properties.paymentInvalid
        }

        // too many decimals
        if (this.countDecimals(parseFloat(value)) > 2) {
            return properties.paymentInvalid
        }

        if (this.exceedsBalance(value)) {
            return properties.paymentExceedsBalance
        }

    }

    render() {
        let processor = this.props.processor

        return (
            <Formik
                initialValues={{
                    payment: this.props.value
                }}
                validateOnChange
                validateOnMount
                onSubmit={values => {
                    let form = document.createElement('form')
                    let params = _.extend(processor, { AMOUNT: values.payment })
                    
                    form.setAttribute('method', 'POST')
                    form.setAttribute('action', params.URL)
                    
                    _.each(params, function(value, name) {
                        let hiddenField = document.createElement('input')
                        
                        hiddenField.setAttribute('type', 'hidden')
                        hiddenField.setAttribute('name', name)
                        hiddenField.setAttribute('value', value)

                        form.appendChild(hiddenField)
                    })

                    document.body.appendChild(form)
                    form.submit()
                }}
                >
                {( { isValid, handleSubmit } ) => (
                    <Form onSubmit={handleSubmit}>
                        <Field
                            name="payment"
                            validate={this.validatePayment}
                        >
                            {({
                                field,
                                form: { errors },
                                meta,
                            }) => (
                                <InputGroup className="mb-3" size="lg">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>$</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        name={field.name}
                                        aria-label="Amount"
                                        value={field.value}
                                        onChange={field.onChange}
                                        isInvalid={meta.error}
                                    />
                                    {meta.error && <div className="invalid-feedback d-block">{errors[field.name]}</div>}
                                </InputGroup>
                            )}
                        </Field>
                        <button type="submit" disabled={!isValid}>
                            {properties.paymentPayNow}
                        </button> 
                    </Form>
                )}
            </Formik>
        )
    }
}

export default PaymentForm