import React from 'react'

import Alert from 'react-bootstrap/Alert'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'

import _ from 'underscore'

import properties from '../properties'

/**
 * TODO: This whole component is entirely out of the original scope of EREG
 * At some point we need to refactor EREG to support templates and have this
 * integrated into a staff award template
 * 
 * Renders a nomination table for Staff Awards Nomination form
 * In support of https://jira.som.yale.edu/browse/SF-972
 * Requirements https://confluence.som.yale.edu/pages/viewpage.action?spaceKey=SSAN&title=Requirements
 *
 * @class NominationTable
 * @extends {React.Component}
 */
class NominationTable extends React.Component {
    constructor() {
        super()

        this.state = {
            error: false,
            rows: []
        }
    }

    getNominations() {
        const id = this.props.id

        // if user is authenticated we will POST to node server and retrieve nomination list
        if (id) {
            fetch('/nominations', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: id
                })
            }).then(results => {
                // catch all server errors
                if (results.status !== 200) {
                    this.setState({
                        error: true
                    })
    
                    return
                }
                
                return results.json()
            }).then(json => {
                console.log(json)
                this.setState({
                    rows: json.rows
                })
            })
        }
    }

    componentDidUpdate() {
        //this.getNominations()
    }

    componentDidMount() {
        this.getNominations()
    }
    
    render() {
        const data = this.props.data

        // only render the table for staffawards template (Staff Awards Form)
        if (data.options.template !== "staffawards") {
            return <></>
        }

        // error fetching nominations
        if (this.state.error) {
            return (
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>
                            {properties.nominationTable.title}
                        </Card.Title>
                        <Table bordered className="mt-2 mb-2">
                            <thead>
                                <tr>
                                    <th>{properties.nominationTable.type}</th>
                                    <th>{properties.nominationTable.name}</th>
                                    <th>{properties.nominationTable.reason}</th>
                                    <th>{properties.nominationTable.role}</th>
                                    <th>{properties.nominationTable.date}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="5">
                                        <Alert variant="danger" className="text-center mt-3 ml-2 mr-2">{properties.nominationTable.error}</Alert>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            )
        }

        return (
            <Card className="mt-3">
                <Card.Body>
                    <Card.Title>
                        {properties.nominationTable.title}
                    </Card.Title>
                    <Table bordered className="mt-2 mb-2">
                        <thead>
                            <tr>
                                <th>{properties.nominationTable.type}</th>
                                <th>{properties.nominationTable.name}</th>
                                <th>{properties.nominationTable.reason}</th>
                                <th>{properties.nominationTable.role}</th>
                                <th>{properties.nominationTable.date}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.rows.map((row, key) => {
                                let date = new Date(row.date).toLocaleDateString("en-US")
                                return (
                                    <tr key={key}>
                                        <td>{row.award_type}</td>
                                        <td>{row.nominee_name}</td>
                                        <td>{row.reason}</td>
                                        <td>{row.role}</td>
                                        <td>{row.date ? date : 'Pending'}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        )
    }    
}

export default NominationTable