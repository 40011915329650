import React from 'react'

import PaymentForm from './components/payment-form'
import EventTitle from './components/event-title'

import Header from './components/header'
import Footer from './components/footer'

import Alert from 'react-bootstrap/Alert'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'

import properties from './properties'
import Feedback from 'react-bootstrap/esm/Feedback'

//import runtimeEnv from '@mars/heroku-js-runtime-env'

//const env = runtimeEnv()
//const API_URL = env.REACT_APP_API_URL || process.env.REACT_APP_API_URL

class PaymentLayout extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: '',
            override: false,
            data: null,
            event: null,
            error: false,
            message: ''
        }
    }

    async componentDidMount() {
        const { 
            match: { params },
            location: { search }
        } = this.props

        let query = new URLSearchParams(search)
        let url = await fetch('/url', { method: 'POST'}).then(res => res.text())

        let citf = query.get('citf')

        // if citf param is present we are dealing with a payment override situation
        // (enrollment period has ended, but we want to allow the user to make a payment anyways)
        // if this is the case, we will validate the value of citf and set this.state.override to true
        // if it is indeed valid (valid=enrollmentId.substring(10,27).reverse() === citf)
        if (citf) {
            let reverse = [...params.enrollmentId.substring(10,27)].reverse().join('')

            if (reverse === citf) {
                this.setState({ override: true })
            }   
        }

        this.setState({
            value: query.get('value')
        })

        fetch(`${url}/api/v1/payment/${params.enrollmentId}${search}`).then(results => {
            // catch all server errors
            if (results.status !== 200) {
                this.setState({
                    error: true,
                    message: `server returned status ${results.status}`
                })

                return
            }
            
            return results.json()
        }).then(json => {
            console.log(json)
            // any status other than 0 is an error
            if (json.status !== 0) {
                this.setState({
                    error: true,
                    message: json.message
                })

                return
            }

            this.setState({
                data: json
            })

            // fetch the event data associated with this payment
            // the event ID was originally USER4
            // however the touchnet upay updates changed the property to PUBLIC_ENROLLMENT_ID
            // so we need to check for USER4 for legacy support, falling back on PUBLIC_ENROLLMENT_ID when its not present 
            let event = json.processor.USER4 || json.processor.PUBLIC_ENROLLMENT_ID
            let path = event.substr(event.lastIndexOf('/') + 1)

            fetch(`${url}/api/v1/event/signup/${path}`).then(results => {
                if (results.status !== 200) {
                    this.setState({
                        error: true,
                        message: `server returned status ${results.status}`
                    })

                    return
                }

                return results.json()
            }).then(json => {
                this.setState({
                    event: json
                })
                console.log(json)
            })

        }).catch(error => {
            this.setState({
                error: true
            })
            console.log(properties.error, error)
        })

    }

    render() {
        let data = this.state.data
        let event = this.state.event

        // Error fetching data
        if (this.state.error) {
            return (
                <>
                    <Header/>
                    <Container id="wrapper">
                        <Alert variant='danger' className="text-center">
                            {`${properties.error}: ${this.state.message}`}
                        </Alert>
                    </Container>
                    <Footer/>
                </>
            )        
        }

        // Initial page load, data is still being fetched
        if (!data && !event) {
            return (
                <>
                    <Header/>
                    <Container id="wrapper">
                        <Alert variant='light' className="text-center">
                            <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            <span className="ml-2">{properties.loading}</span>
                        </Alert> 
                    </Container>
                    <Footer/>
                </>
            )
        }
        
        // Data fetch was successful
        if (data && event) {
            // Event is marked inactive or not accepting enrollments/payments
            // And override is false
            if ((!event.active || !event.accepting_enrollmnet.is_accepting_enrollment) && !this.state.override) {
                return (
                    <>
                        <Header/>
                        <Container id="wrapper">
                            <EventTitle data={data}/>
                            <Alert variant='warning'>
                                {properties.paymentClosed}
                            </Alert>
                        </Container>
                        <Footer/>   
                    </>
                )    
            }

            // Event is marked active and is accepting enrollments/payments
            return (
                <>
                    <Header/>
                    <Container id="wrapper">
                        <EventTitle data={data}/>
                        <Card style={{ width: '20rem', margin: '2em auto' }}>
                            <Card.Body>
                                <Card.Title>
                                    {properties.paymentTitle}
                                </Card.Title>
                                <PaymentForm data={data} processor={data.processor} value={this.state.value}/>
                            </Card.Body>
                        </Card>
                    </Container>
                    <Footer/>
                </>
            )
        }

        return <></>
    }
}

export default PaymentLayout