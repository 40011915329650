import React from 'react'

import QuestionText from './question-text.js'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

/**
 * Renders radio input
 *
 * @class Radio
 * @extends {React.Component}
 */
class Radio extends React.Component {
    render() {
        const props = this.props
        const field = props.field
        const values = field.question_values.split('\n')

        return (
            <Form.Group as={Row} controlId={field.id}>
                <QuestionText field={field} column={true} sm={3}/>
                <Col sm="9">
                    {values.map((value, key) => {
                        return (
                            <Form.Check
                                key={key}
                                type="radio"
                                label={value}
                                name={field.id}
                                id={field.id + '-' + key}
                                className={props.className}
                                value={value}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                isValid={props.touched[field.id] && !props.errors[field.id]}
                                isInvalid={props.touched[field.id] && !!props.errors[field.id]}
                            />
                        )
                    })}
                    <Form.Control.Feedback type="invalid">
                            {props.errors[field.id]}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
        )
    }    
}

export default Radio