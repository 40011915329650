import React from 'react'
import Form from 'react-bootstrap/Form'

/**
 * Renders question text (marking with an asterisk if field is required)
 *
 * @class QuestionText
 * @extends {React.Component}
 */
class QuestionText extends React.Component {
    render() {
        const field = this.props.field

        return (
            <Form.Label column={this.props.column} sm={this.props.sm}>
                <span dangerouslySetInnerHTML={{ __html: field.question_text }}/>
                {field.is_required === true && <span style={{color: "red"}}>*</span>}   
            </Form.Label>    
        )
    }
}

export default QuestionText