import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import FormLayout from './FormLayout'
import PaymentLayout from './PaymentLayout'
import QualtricsLayout from './QualtricsLayout'

const App = () => {
    return (
        <Router>
            <Switch>
                <Route path="/payment/:enrollmentId" component={PaymentLayout}/>
                <Route path="/qualtrics/:formId" component={QualtricsLayout}/>
                <Route path="/:formId" component={FormLayout} /> 
            </Switch>
        </Router>
    )
}

export default App