export default class CAS {
    constructor() {
        let params = new URLSearchParams(window.location.search)
        let ticket = params.get('ticket')

        this.ticket = ticket
        this.id = null
    }

    login() {
        let service = encodeURIComponent(window.location.origin + window.location.pathname)
        window.location = `https://secure.its.yale.edu/cas/login?service=${service}`
    }

    logout() {
        let service = encodeURIComponent(window.location.origin + window.location.pathname)
        window.location = `https://secure.its.yale.edu/cas/logout?service=${service}`
    }

    hasTicket() {
        return !!this.ticket
    }

    // this removes the CAS ticket URL param without causing the browser back button to get stuck in a redirect loop
    cleanURL() {
        window.history.replaceState({}, document.title, window.location.pathname);
    }

    async validate(ticket) {
        let service = encodeURIComponent(window.location.origin + window.location.pathname)
        let response = await fetch('/validate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ticket: ticket,
                service: service
            })
        })
        
        return response
    }

    async parse(response) {
        return await response.text().then(str => {
            let line = str.split('\n')

            return {
                success: line[0] === 'yes',
                id: line[1]    
            }
        })
    }

    async isAuthenticated() {
        if (!this.ticket) {
            return { success: false }
        }

        return await this.validate(this.ticket)
            .then(response => this.parse(response))
            .then(obj => {
                if (obj.success) {
                    this.id = obj.id
                }
                return obj
            })
    }

    async person() {
        if (!this.id) {
            return null
        }

        return await fetch('/person', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: this.id    
            })
        })
        .then(response => response.json())
        .then(json => {
            if (!json[0].data) {
                return null
            }
            return json[0].data.items[0]
        })
    }
}