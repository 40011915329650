import React from 'react'

import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import InputGroup from 'react-bootstrap/InputGroup'

import properties from '../properties'


/**
 * A component to render gift intention form inputs
 *
 * @class Gift
 * @extends {React.Component}
 */
class Gift extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            otherSelected: false,
            otherValue: '',
            totalAmount: 0
        }

        this.formatInstallment = this.formatInstallment.bind(this)
        this.formatTotal = this.formatTotal.bind(this)
        this.formatOther = this.formatOther.bind(this)
    }

    formatOther(total) {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)
    }

    formatInstallment(total) {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total/4)
    }

    formatTotal(installment) {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat(installment.substr(1).replace(',',''))*4)
    }

    render() {
        const props = this.props
        const field = props.field
        const values = field.question_values.split('\n')

        let schedule = field.classgift_installment_schedule
        let displayInstallments = props.displayInstallments
        
        let installment = this.formatInstallment(this.state.totalAmount)
        let grandTotal = this.formatTotal(installment)
        
        return (
            <>
                <Form.Control name={`${field.id}-installment`} type="hidden" value={installment.substr(1)}/>
                <Table className="gift-component" striped bordered style={{ marginBottom: !displayInstallments ? '1rem' : 0 }}>
                    <thead>
                        <tr>
                            <td><b>{field.question_text}</b></td>
                        </tr>
                    </thead>
                    <tbody>
                        {values.map((value, key) => {
                            if (!value.toLowerCase().includes('other')) {
                                return (
                                    <tr key={key}>
                                        <td>
                                            <Form.Check
                                                type="radio"
                                                label={value}
                                                name={field.id}
                                                id={field.id + '-' + key}
                                                value={value}
                                                onChange={e => {
                                                    let total = Number(value.replace(/[^0-9.-]+/g,""))
                                                    let fieldInstallment = this.formatInstallment(total)
                                                    let fieldTotal = this.formatTotal(fieldInstallment)
                                                    
                                                    this.setState({ 
                                                        otherSelected: false,
                                                        otherValue: '',
                                                        totalAmount: total
                                                    }, function() {
                                                        
                                                    })
                                                    
                                                    props.setFieldValue(field.id, JSON.stringify({ total: fieldTotal, installment: fieldInstallment }))
                                                }}
                                                onBlur={props.onBlur}
                                                isValid={props.touched[field.id] && !props.errors[field.id]}
                                                isInvalid={props.touched[field.id] && !!props.errors[field.id]}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                            
                            // render "other field" which includes a text input
                            return (
                                <tr key={key}>
                                    <td>
                                        <Form.Check
                                            type="radio"
                                            label={value}
                                            name={field.id}
                                            id={field.id + '-' + key}
                                            value={this.state.otherValue}
                                            onChange={e => {
                                                this.setState({ 
                                                    otherSelected: true,
                                                    totalAmount: null
                                                }, function() {
                                                    this.otherInput.focus()
                                                })

                                                props.setFieldValue(field.id, '')
                                            }}
                                            onBlur={props.onBlur}
                                            isValid={props.touched[field.id] && !props.errors[field.id]}
                                            isInvalid={props.touched[field.id] && !!props.errors[field.id]}
                                        />
                                        <div className="mt-3 ml-3">
                                            <Form.Label htmlFor={`${key}-other-amount`}>Other Amount</Form.Label>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>$</InputGroup.Text>
                                                <Form.Control
                                                    id={`${key}-other-amount`}
                                                    type="text"
                                                    ref={(input) => { this.otherInput = input }}
                                                    value={this.state.otherValue} 
                                                    disabled={!this.state.otherSelected}
                                                    placeholder={properties.enterAmount}
                                                    onChange={e => {
                                                        let value = e.target.value
                                                        let total = Number(value.replace(/[^0-9.-]+/g,""))
                                                        let fieldInstallment = this.formatInstallment(total)
                                                        let fieldTotal = displayInstallments ? this.formatTotal(fieldInstallment) : this.formatOther(total)

                                                        this.setState({
                                                            totalAmount: total,
                                                            otherValue: value  
                                                        })

                                                        props.setFieldValue(field.id, JSON.stringify({ total: fieldTotal, installment: fieldInstallment }))
                                                    }}
                                                />
                                            </InputGroup>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {displayInstallments && 
                    <Table className="gift-component" striped bordered>
                        <tbody>
                            <tr>
                                <td>Installment 1:</td>
                                <td>{installment}</td>
                                <td>{schedule[1]}</td>
                            </tr>
                            <tr>
                                <td>Installment 2:</td>
                                <td>{installment}</td>
                                <td>{schedule[2]}</td>
                            </tr>
                            <tr>
                                <td>Installment 3:</td>
                                <td>{installment}</td>
                                <td>{schedule[3]}</td>
                            </tr>
                            <tr>
                                <td>Installment 4:</td>
                                <td>{installment}</td>
                                <td>{schedule[4]}</td>
                            </tr>
                            <tr>
                                <td>Total Amount:</td>
                                <td>{grandTotal}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                }
            </>
        )
    }    
}

export default Gift