import React from 'react'
import moment from 'moment'
import properties from '../properties'

// TODO: Temp hack for ER-78, to be removed
import runtimeEnv from '@mars/heroku-js-runtime-env'
const env = runtimeEnv()
const production = env && env.REACT_APP_ENV === "production"
/**/

class EventDetails extends React.Component {
    constructor() {
        super()

        this.displayHack = this.displayHack.bind(this)
    }

    /*  TODO: Temp hack for ER-78, this should be an admin config setting,
        we are hiding this display for two CIMA programs in production based on their EREG event ids (22 and 56)
    */
    displayHack(el) {
        let data = this.props.data

        if (production && data.id === 22) {
            return <li/>
        }

        if (production && data.id === 56) {
            return <li/>
        }

        if (el === "ical") {
            return (
                <li>
                    <a href={data.ical_url}>{properties.ical}</a>
                </li>
            )
        }

        if (el === "date") {
            return <li>{`${moment(data.event_start_datetime).format('MMMM Do')} through ${moment(data.event_end_datetime).format('MMMM Do')}`}</li>
        }
    }

    render() {
        let data = this.props.data
        let template = data.options.template

        const location = () => {
            return (
                <ul className="list-unstyled">
                    {data.location && 
                        <li className="font-weight-bold">
                            {data.location.split('\n').map((item, i) => <React.Fragment key={i}>{item}<br/></React.Fragment>)}
                        </li>
                    }
                    {data.map_location && 
                        <li className="small">
                            {data.map_location.split('\n').map((item, i) => <React.Fragment key={i}>{item}<br/></React.Fragment>)}
                        </li>
                    }

                    {/* TO BE REMOVED - FOR ER-78 */}
                    {this.displayHack('ical')}
                    <div id="map"/>
                </ul>
            )
        }

        switch(template) {
            default:
            case "default":
                return (
                    <>
                        <ul className="list-unstyled">
                            <li>{moment(data.event_start_datetime).format('dddd, MMMM D, YYYY, h:mm A')}</li>
                            <li>{moment(data.event_end_datetime).format('dddd, MMMM D, YYYY, h:mm A')}</li>
                        </ul>
                        {location()}
                    </>
                )
            
            // custom layout for Exec Ed, triggered by the use of the "salesforce" or "conference" template
            case "salesforce":
            case "conference":
                return (
                    <>
                        <ul className="list-unstyled">
                            {/* TO BE REMOVED - FOR ER-78 */}
                            {this.displayHack('date')}
                        </ul>
                        {location()}
                    </>
                )
        }
    }
}

export default EventDetails