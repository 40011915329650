import React from 'react'
import _ from 'underscore'

import EventTitle from './components/event-title'
import EventForm from './components/event-form'
import EventDetails from './components/event-details'

import Header from './components/header'
import Footer from './components/footer'

import properties from './properties'

import Alert from 'react-bootstrap/Alert'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'

import * as atlas from 'azure-maps-control'
import 'azure-maps-control/dist/atlas.min.css'

import './styles/yalesom-theme.main.min.css'
import './styles/bootstrap.min.css'
import './styles/App.css'

//import runtimeEnv from '@mars/heroku-js-runtime-env'

import CAS from './cas'
import NominationTable from './components/nomination-table'

const cas = new CAS()

//const env = runtimeEnv()
//const API_URL = process.env.REACT_APP_API_URL

class FormLayout extends React.Component {
    constructor() {
        super()

        this.state = {
            data: null,
            error: false,
            id: null
        }
    }

    async componentDidMount() {
        const { match: { params } } = this.props
        
        // Get the event URL from pathname
        const path = params.formId

        let url = await fetch('/url', { method: 'POST'}).then(res => res.text())
        
        if (!path) {
            this.setState({
                error: true
            })

            return
        }

        // fetch the event form json data
        fetch(`${url}/api/v1/event/signup/${path}`).then(results => {
            // catch all server errors
            if (results.status !== 200) {
                this.setState({
                    error: true
                })

                return
            }
            
            return results.json()
        }).then(async json => {
            console.log(json)

            // Use CAS boolean from Admin config
            let useCAS = json.options.use_cas

            // if we are securing the form with CAS and we don't already have a ticket from CAS we redirect the user to CAS login page
            if (useCAS) {
                if (!cas.hasTicket()) {
                    cas.login()
                    return
                }
                
                // async call to Node server to validate authentication
                // auth = { success: Boolean, id: NetID }
                let auth = await cas.isAuthenticated()
                
                // if authentication was successful set data and id, clean the URL (remove CAS ticket URL param)
                if (auth.success) {
                    cas.cleanURL()
                    console.log(auth)

                    // Get person info through Person Search API
                    let person = await cas.person()

                    // if a person is found through Person Search we will pre-fill question values for Firstname, Lastname, and E-mail
                    if (person) {
                        console.log(person)
                        _.each(json.fields, function(field) {
                            switch(field.question_type) {
                                case 'firstname':
                                    field.question_values = person.firstName
                                    break

                                case 'lastname':
                                    field.question_values = person.lastName
                                    break
                                
                                case 'email':
                                    field.question_values = person.emailPreferred
                                    break
                            }
                        })
                    }
                    
                    /*
                        TODO: Hack for Staff Award Nomination for (https://jira.som.yale.edu/browse/SF-972)
                        A requirement for this form is to hide first name, last name, email address fields
                        We don't have a generic way of configuring hidden fields in EREG yet, so we'll force
                        the fields to be hidden regardless of whether or not the Person Search pre-populated
                        the fields with data - this needs to happen before the form is rendered

                        Another requirement that is specific to Staff Award Nomination is to pre-fill
                        first name, last name, email address fields with dummy data when Person Search
                        returns no results - this ensures hidden fields will pass client-side validation and
                        the form will still be submittable
                    */
                    if (json.options.template === "staffawards") {
                        _.each(json.fields, function(field) {
                            switch(field.question_type) {
                                case 'firstname':
                                    field.is_hidden = true

                                    if (!person) {
                                        field.question_values = "null"
                                    }

                                    break

                                case 'lastname':
                                    field.is_hidden = true

                                    if (!person) {
                                        field.question_values = "null"
                                    }

                                    break
                                
                                case 'email':
                                    field.is_hidden = true
                                    
                                    if (!person) {
                                        field.question_values = "null@null.com"
                                    }
                                    break
                            }
                        })

                    }

                    this.setState({
                        data: json,
                        id: auth.id
                    })

                    return
                }
                
                // if authentication wasn't successful
                this.setState({
                    error: true
                })

                return
            }

            // if we are not securing the form with CAS set data
            this.setState({
                data: json
            })
        }).catch(error => {
            this.setState({
                error: true
            })
            console.log(properties.error, error)
        })
        
    }

    componentDidUpdate() {
        if (this.state.error) {
            return
        } 
        
        this.loadMap()
    }

    /**
     * Render an Azure Map
     *
     * @memberof App
     */
    loadMap() {
        let location = this.state.data.map_location
        let url = `//atlas.microsoft.com/search/address/json?api-version=1.0&subscription-key=gLHdl0_rGeyQn3jC6HgNC96u9GfRHokEvIJNtAEXYP0&query=${location}`
        let mapDOM = document.getElementById('map')
        
        if (!mapDOM) {
            return
        }

        if (!location) {
            mapDOM.style.display = 'none'
            return
        }

        // call Azure Map API with location address to get coordinates
        fetch(url).then(results => {
            // catch all server errors
            if (results.status !== 200) {
                mapDOM.style.display = 'none'
                return
            }
            
            return results.json()
        }).then(json => {
            // Azure MAP API returns an array of results, the first result being the best match
            // See https://docs.microsoft.com/en-us/azure/azure-maps/how-to-search-for-address
            let result = json.results[0]

            if (!result) {
                mapDOM.style.display = 'none'
                return
            }

            // See https://docs.microsoft.com/en-us/azure/azure-maps/how-to-use-map-control
            let map = new atlas.Map('map', {
                center: [result.position.lon, result.position.lat],
                zoom: 15,
                language: 'en-US',
                authOptions: {
                    authType: 'subscriptionKey',
                    subscriptionKey: 'gLHdl0_rGeyQn3jC6HgNC96u9GfRHokEvIJNtAEXYP0'
                }
            })

            // See https://docs.microsoft.com/en-us/azure/azure-maps/map-add-custom-html
            map.events.add('ready', function () {
                //Create a HTML marker and add it to the map.
                let marker = new atlas.HtmlMarker({
                    position: [result.position.lon, result.position.lat],
                    popup: new atlas.Popup({
                        content: `<div style="padding:10px">${location}</div>`,
                        pixelOffset: [0, -30]
                    }),
                    pixelOffset: [10, 18]
                })
                
                map.markers.add(marker)
                
                //Add a click event to toggle the popup.
                map.events.add('click', marker, () => {
                    marker.togglePopup()
                })
            })
        }).catch(error => {
            console.log('map', error)
        })
    }

    render() {
        // Error fetching data
        if (this.state.error) {
            return (
                <>
                    <Header/>
                    <Container id="wrapper">
                        <Alert variant='danger' className="text-center">
                            {properties.error}
                        </Alert>
                        <div className="text-center">
                            <button variant="primary" onClick={() => { this.componentDidMount() }}>{properties.retry}</button>
                        </div>
                    </Container>
                    <Footer/>
                </>
            )        
        }

        // Initial page load, data is still being fetched
        if (!this.state.data) {
            return (
                <>
                    <Header/>
                    <Container id="wrapper">
                        <Alert variant='light' className="text-center">
                            <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            <span className="ml-2">{properties.loading}</span>
                        </Alert>    
                    </Container>
                    <Footer/>
                </>
            )
        }

        // Data fetch was successful
        if (this.state.data) {
            let data = this.state.data

            // Display warning when event is inactive
            if (!data.active) {
                return (
                    <>
                        <Header/>
                        <Container id="wrapper">
                            <EventTitle data={data}/>
                            <Alert variant='warning'>
                                {properties.inactive}
                            </Alert>
                        </Container>
                        <Footer/>    
                    </>
                )
            }

            // Event is marked as active
            if (data.active) {
                
                // Event is not accepting enrollment
                if (!data.accepting_enrollmnet.is_accepting_enrollment) {
                    return (
                        <>
                            <Header/>
                            <Container id="wrapper">
                                <EventTitle data={data}/>
                                <Alert variant='warning'>
                                    <div dangerouslySetInnerHTML={{__html: data.accepting_enrollmnet.msg}}/>
                                </Alert>
                            </Container>
                            <Footer/>   
                        </>
                    )    
                }

                let hideSidePanel = !data.options.template_display_attributes.template_string_replace_when_and_where
                // Event is active and accepting enrollment
                return (
                    <>
                        <Header/>
                        <Container id="wrapper">
                            <EventTitle data={data}/>
                            <Row id="form-wrapper">
                                {/*
                                    TODO: hideSidePanel is a hack added for class gift/staff award forms, which use a full-page layout
                                    and do not have when/where/hosted by data to display. Eventually we will want to improve
                                    template on the front end
                                */}
                                <Col sm={hideSidePanel ? 12 : 8} className="p-3">
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>{data.options.template_string_replace_form_submission}</Card.Title>
                                            {this.state.id &&
                                                <Alert variant="success">
                                                    <div className="clearfix">
                                                    <span className="float-left">{`Logged in as ${this.state.id}`}</span>
                                                    <span className="float-right">
                                                        <a href="#" onClick={e => { e.preventDefault(); cas.logout() }}>Logout</a>
                                                    </span>
                                                    </div>
                                                </Alert>
                                            }
                                            <EventForm
                                                id={this.state.id}
                                                data={data}
                                                fields={data.fields}
                                                url={data.enrollment_url}
                                                history={this.props.history}
                                            />
                                        </Card.Body>
                                    </Card>
                                    
                                    {/*
                                        TODO: Entirely out of scope for EREG, only used by Staff Awards Nomination form
                                        to diplsay nominations made by the user logged into CAS

                                        This should eventually be refactored into some type of templating system
                                        https://jira.som.yale.edu/browse/SF-972
                                    */}
                                    <NominationTable id={this.state.id} data={data}/>
                                </Col>
                                {!hideSidePanel &&
                                    <Col sm={4} className="p-3">
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>{data.options.template_string_replace_when_and_where}</Card.Title>
                                                <EventDetails data={data}/>
                                            </Card.Body>
                                        </Card>
                                        {data.hosted_by && 
                                            <Card className="mt-3">
                                                <Card.Body>
                                                    <Card.Title>{properties.hostedBy}</Card.Title>
                                                    <Card.Text dangerouslySetInnerHTML={{ __html: data.hosted_by}}/>
                                                </Card.Body>
                                            </Card>
                                        }
                                    </Col>
                                }
                            </Row>
                        </Container>
                        <Footer/>
                    </>
                )
            }
        }
    }
}

export default FormLayout