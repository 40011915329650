import React from 'react'

class EventTitle extends React.Component {
    render() {
        let data = this.props.data
        let template = data.options ? data.options.template : null
        return (
            <>
                {/*
                    TODO: This is a bit of a hack to only display Exec Ed headers, which should render if the default template is set
                    Now that we use EREG for class gift forms, and likely other future forms outside of Exec Ed we should improve how
                    Templating is handled on the front-end (rather than conditional rendering all over the place)
                */}
                {template === "default" &&
                <>
                    <h1 id="page-title" className="page__title title">Executive Education</h1>
                    <h2 className="page__sub-title sub-title">Leaders are learners. Learners come to Yale.</h2>
                </>
                }
                <h2 className="event-title">{data.title}</h2>
            </>
        )
    }
}

export default EventTitle