import React from 'react'
import yalesom_tag from '../images/yalesom_tag.svg'
import Yale_University_logo from '../images/Yale_University_logo.svg'
import GNAM from '../images/GNAM.svg'
import shield from '../images/yalesom_shield-alt.svg'
const Footer = () => {
    return (
        <footer id="site-footer">
            <div className="footer--blocks">
                <div id="block-block-20" className="block-block-20 block block-block">
                </div>
            </div>
            <div className="footer--content">
                <img src={shield} className="shield-footer" alt="Yale SOM shield" />
                <div className="address">
                    <span className="delta">EDWARD P. EVANS HALL</span>
                    <br /> 165 Whitney Avenue
                    <br /> New Haven, CT 06511-3729
                    <br />
                    <a href="https://som.yale.edu/about/plan-visit">Plan a Visit</a>
                    <a href="https://goo.gl/maps/rKB17uiuY5s">Map</a>
                </div>
                <nav className="navigaton--footer footer-links" aria-labelledby="navigation_footer_title">
                    <h2 className="element-invisible" id="navigation_footer_title">Footer Navigation</h2>
                    <ul className="nav--list">
                        <li className="first level-1 nav--our-programs"><a href="https://som.yale.edu/programs" title="">Our Programs</a></li>
                        <li className="level-1 nav--yale-insights"><a href="http://insights.som.yale.edu">Yale Insights</a></li>
                        <li className="level-1 nav--alumni"><a href="https://som.yale.edu/community/alumni">Alumni</a></li>
                        <li className="level-1 nav--faculty-&amp;-research"><a href="https://som.yale.edu/faculty-research-centers">Faculty &amp; Research</a></li>
                        <li className="level-1 nav--about-yale-som"><a href="https://som.yale.edu/about" title="About Yale SOM: Connect, Explore, Discover">About Yale SOM</a></li>
                        <li className="level-1 nav--for-recruiters"><a href="https://som.yale.edu/community/recruiting">For Recruiters</a></li>
                        <li className="level-1 nav--site-map"><a href="https://som.yale.edu/site-map">Site Map</a></li>
                        <li className="level-1 nav--accessibility"><a href="https://usability.yale.edu/web-accessibility/accessibility-yale" title="Yale Accessibility">Accessibility</a></li>
                        <li className="level-1 nav--privacy-policy"><a href="https://www.yale.edu/privacy-policy" title="Privacy Policy">Privacy Policy</a></li>
                        <li className="last level-1 nav--contact----"><a href="https://som.yale.edu/about/contact">Contact    </a></li>
                    </ul>
                </nav>
            </div>
            <div className="footer--copyright">
                <img src={yalesom_tag} className="tagline" alt="Educating leaders for business and society" />
                <p>© Copyright 2000-2022 Yale School of Management. All rights reserved.</p>
                <div className="logos">
                    <a href="http://www.yale.edu" className="yale no-icon"><img src={Yale_University_logo} alt="Yale University" /></a>
                    <a href="http://advancedmanagement.net" className="gnam no-icon"><img src={GNAM} alt="Global Network for Advanced Management" /></a>
                </div>
            </div>

        </footer> 
    )
}

export default Footer