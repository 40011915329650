import React from 'react'
import {Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import properties from '../properties.js'
import QuestionText from './question-text.js'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


/**
 * Renders a type ahead input
 * https://www.npmjs.com/package/react-bootstrap-typeahead
 *
 * @class TypeaheadInput
 * @extends {React.Component}
 */
class TypeaheadInput extends React.Component {
    render() {
        const props = this.props
        const field = props.field
        const values = field.question_values
        
        return (
            <Form.Group as={Row} controlId={field.id}>
                <QuestionText field={field} column={true} sm={3}/>
                <Col sm="9">
                    <Typeahead
                        id={`${field.id}-typeahead`}
                        inputProps={{ id: field.id }}
                        labelKey="label"
                        minLength={1}
                        highlightOnlyResult={true}
                        options={values}
                        placeholder={properties.typeaheadPlaceholder}
                        onInputChange={value => {
                            props.setFieldValue(field.id, value)
                        }}
                        onChange={value => {
                            // only set a field value when a selection from the menu is made
                            if (value[0]) {
                                props.setFieldValue(field.id, value[0])
                            }
                        }}
                        onBlur={props.onBlur}
                        isValid={props.touched[field.id] && !props.errors[field.id]}
                        isInvalid={props.touched[field.id] && !!props.errors[field.id]}
                    />
                    {
                        props.touched[field.id]
                        && !!props.errors[field.id]
                        && <div className="invalid-feedback d-block">{props.errors[field.id]}</div>
                    }
                </Col>
            </Form.Group>
        )
    }    
}

export default TypeaheadInput