import React from 'react'
import Logo from '../images/yale-som-logo.png'
class Header extends React.Component {
    constructor() {
        super()

        this.state = {
            showNav: false
        }

        this.headerVisibility = this.headerVisibility.bind(this)
    }

    headerVisibility() {
        let headerDOM = document.getElementById('page--header-wrap')
        let headerStyles = window.getComputedStyle(headerDOM)
        let display = headerStyles.getPropertyValue('display')
        
        return display === 'block'
    }

    toggleMobileNav() {
        let headerDOM = document.getElementById('page--header-wrap')
        let visibility = this.headerVisibility()
        
        headerDOM.setAttribute('style', visibility ? "display: none" : "display: block")
    }

    render() {
        return (
            <header id="site-header" role="banner" className="">
                <div id="yalesom-logo">
                    <a href="https://som.yale.edu/" title="Yale School of Management"><img alt="Yale School of Management" src={Logo} className="logo"/></a>
                </div>
                <div className="page--header">
                    <button onClick={() => { this.toggleMobileNav() }} id="navigation--mobile-toggle" aria-label="Primary navigation toggle" aria-controls="js-navigation-wrap"><span></span><span></span><span></span><span></span></button>
                    <div id="page--header-wrap">
                        <div className="sticky-wrapper">
                            <nav id="block-som-adv-menu-som-adv-menu" className="block-som-adv-menu-som-adv-menu block block-som-adv-menu navigation" aria-labelledby="navigation_block-som-adv-menu-som-adv-menu_title">
                                {/*<h2 id="navigation_block-som-adv-menu-som-adv-menu_title" className="element-invisible">Primary navigation</h2>*/}
                                {/*<button className="accordion-expand">Expand all</button>*/}
                                <ul className="nav__list nav__list-depth-1" data-accordion="loaded">
                                    <li className="nav--mission nav__item nav__item-depth-1 nav__type-expanded" data-tid="5801" data-parent-tid="0"><a href="https://som.yale.edu/mission" className="nav__link nav__link-depth-1">Mission</a>
                                    </li>
                                    <li className="nav--programs nav__item nav__item-depth-1 nav__type-expanded" data-tid="5802" data-parent-tid="0"><a href="https://som.yale.edu/programs" className="nav__link nav__link-depth-1">Programs</a>
                                    </li>
                                    <li className="nav--faculty-research--centers nav__item nav__item-depth-1 nav__type-expanded" data-tid="5798" data-parent-tid="0"><a href="https://som.yale.edu/faculty-research-centers" className="nav__link nav__link-depth-1">Faculty Research &amp; Centers</a>
                                    </li>
                                    <li className="nav--community nav__item nav__item-depth-1 nav__type-expanded" data-tid="5799" data-parent-tid="0"><a href="https://som.yale.edu/community" className="nav__link nav__link-depth-1">Community</a>
                                    </li>
                                    <li className="nav--about nav__item nav__item-depth-1 nav__item--sparse nav__type-expanded" data-tid="5800" data-parent-tid="0"><a href="https://som.yale.edu/about" className="nav__link nav__link-depth-1">About</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header