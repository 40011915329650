import React from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

/**
 * Renders a full-width field displaying question text
 *
 * @class Label
 * @extends {React.Component}
 */
class Label extends React.Component {
    render() {
        const field = this.props.field

        return (
            <Form.Group as={Row} controlId={field.id}>
                <Col>
                    <div dangerouslySetInnerHTML={{ __html: field.question_text }}/>
                </Col>
            </Form.Group>
        )
    }    
}

export default Label