import React from 'react'

import QuestionText from './question-text.js'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FaSort } from "react-icons/fa"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import _ from 'underscore'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result;
}

// some basic styles to make the items look a bit nicer
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: 16,
  margin: '0 0 8px 0',
  color: isDragging ? '#856404' : '#fff',

  // change background color if dragging
  background: isDragging ? '#FFF3CD' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle
})

const getListStyle = () => ({
  background: '#d3d3d3',
  padding: 8
})

/**
 * Renders a drag and drop sortable list using react-beautiful-dnd
 * https://github.com/atlassian/react-beautiful-dnd
 *
 * @class Rank
 * @extends {React.Component}
 */
class Rank extends React.Component {
    constructor(props) {
        super(props)

        // TODO: See if we can get these values from an array rather than newline delimited list
        let values = this.props.field.question_values.split("\n").map((value, key) => {
            return {
                id: `item-${key}`,
                content: value
            }
        })

        this.state = {
          items: values
        }

        this.onDragEnd = this.onDragEnd.bind(this)
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const items = reorder(
          this.state.items,
          result.source.index,
          result.destination.index
        )
        
        this.setState({
          items
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(
                    _.map(items, item => {
                        return item.content
                    }).join('\n')
                )
            }
        })
    }

    render() {
        const field = this.props.field

        return (
            <Form.Group as={Row} controlId={field.id}>
                <QuestionText field={field} column={true} sm={3}/>
                <Col sm="9" className={this.props.className}>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {this.state.items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                        )}
                                    >
                                        <FaSort/>
                                        <span style={{ paddingLeft: 8 }}>{item.content}</span>
                                    </div>
                                    )}
                                </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Col>
            </Form.Group>
        )
    }    
}

export default Rank