import React from 'react'

import QuestionText from './question-text.js'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


/**
 * Renders a field for registration fees
 *
 * @class Fee
 * @extends {React.Component}
 */
class Fee extends React.Component {
    render() {
        const props = this.props
        const field = props.field
        const subType = field.question_sub_type
        const values = field.fee_values.fees

        switch (subType) {
            default:
            case "single_line": 
                return (
                    <Form.Group as={Row} controlId={field.id}>
                        <QuestionText field={field} column={true} sm={3}/>
                        <Col sm="9">
                            {values.map((value, key) => {
                                return (
                                    <div key={key} className="col-form-label">
                                        <FeeLabel value={value} index={key} field={field}/>
                                    </div>
                                )   
                            })}
                        </Col>
                    </Form.Group>
                )
            
            case "radio":
                return (
                    <Form.Group as={Row} controlId={field.id}>
                        <QuestionText field={field} column={true} sm={3}/>
                        <Col sm="9">
                            {values.map((value, key) => {
                                return (
                                    <Form.Check
                                        key={key}
                                        type="radio"
                                        name={field.id}
                                        id={field.id + '-' + key}
                                        value={value.formvalue}
                                        label={`${value.display_text} - $${value.amount}`}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        isValid={props.touched[field.id] && !props.errors[field.id]}
                                        isInvalid={props.touched[field.id] && !!props.errors[field.id]}
                                    />
                                )   
                            })}
                            <Form.Control.Feedback type="invalid">
                                {props.errors[field.id]}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                )
            
            case "checkbox":
                return (
                    <Form.Group as={Row} controlId={field.id}>
                        <QuestionText field={field} column={true} sm={3}/>
                        <Col sm="9">
                            {values.map((value, key) => {
                                return (
                                    <Form.Check 
                                        key={key}
                                        name={field.id}
                                        id={field.id + '-' + key}
                                        value={value.formvalue}
                                        label={`${value.display_text} - $${value.amount}`}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        isValid={props.touched[field.id] && !props.errors[field.id]}
                                        isInvalid={props.touched[field.id] && !!props.errors[field.id]}
                                    />
                                )   
                            })}
                            <Form.Control.Feedback type="invalid">
                                    {props.errors[field.id]}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                )
        }
    }    
}

class FeeLabel extends React.Component {
    render() {
        const { /*field,*/ value } = this.props
        //const isEarlyFee = new Date(field.options.early_fee_datetime) > new Date()

        return (
            <React.Fragment>
                {value.display_text}
                {" $"}
                {value.amount}
                {/*isEarlyFee ? value.early_fee : value.base_fee*/}
                {/* TODO: Not implemented in this version */}
                {/*value.attribs.quantity_input && " x "*/}
                {/*value.attribs.quantity_input && 
                    <Form.Control
                        type="number"
                        min={value.attribs.quantity_zero_allowed ? 0 : 1}
                        name={`${field.id}-${index}`}
                        style={{ 
                            width: 80,
                            display: 'inline-block'
                        }}
                    />
                    */}
            </React.Fragment>
        )
    }
}

export default Fee