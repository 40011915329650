const properties = {
    closeButton: 'Close',
    okButton: 'Ok',
    loading: 'Loading... Please Wait',
    error: 'There was an error processing your request',
    inactive: 'This event is currently inactive',
    submit: 'Submit',
    select: 'Please Select',
    retry: 'Retry',
    ical: 'Download to Calendar',
    hostedBy: 'Hosted By',
    typeaheadPlaceholder: 'Start typing...',
    enterAmount: 'Enter amount here',
    checkboxesRequired: 'At least one selection is required',
    requiredField: 'This is a required field',
    requiredMin: 'Must be greater than ${min}',
    requiredMax: 'Must be less than ${max}',
    invalidForm: 'Could not complete your registration. Please review your submission and try again.',
    invalidEmail: 'The email you entered is invalid',
    registrationComplete: 'Your registration is complete',
    registrationFail: 'Your registration could not be completed',
    registrationError: 'There was an error processing your registration. Please try again later.',
    registrationNextStep: 'Next Step',
    registrationPayNow: 'Click Here to Pay Now',
    registrationPayLater: 'Pay Later (via Invoice)',
    termsAndConditions: 'Terms and Conditions',
    termsAndConditionsAccept: 'I AGREE',
    termsAndConditionsDecline: 'I DO NOT AGREE',
    paymentPayNow: 'Click Here to Pay Now',
    paymentTitle: 'Enter your payment amount',
    paymentInvalid: 'Invalid amount',
    paymentExceedsBalance: 'The amount exceeds current account balance',
    paymentClosed: 'This event is no longer accepting payments',
    nonYaleEmail: 'Must not be a Yale e-mail address',
    reunionGiftCampaign: 'Reunion Gift Campaign',
    qualtrics: {
        logo: 'Broad Center Logo',
        uploadFail: 'Your upload could not be completed',
        uploadError: 'There was an error processing your upload. Please try again later.',
        uploadComplete: 'Your upload is complete'
    },
    nominationTable: {
        title: 'Nominations by Me',
        type: 'Award Type',
        name: 'Nominee Name',
        reason: 'Award Category',
        role: 'Relationship',
        date: 'Date Submitted',
        error: 'There was an error fetching your nominations'
    }
}

export default properties