import React from 'react'


import QualtricsForm from './components/qualtrics-form'

import properties from './properties'

import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'

import _ from 'underscore'

import './styles/qualtrics.css'
import './styles/bootstrap.min.css'
//import './styles/App.css'

import Logo from './images/broad-center-logo.jpg'

//import runtimeEnv from '@mars/heroku-js-runtime-env'

//const env = runtimeEnv()
//const API_URL = env.REACT_APP_API_URL || process.env.REACT_APP_API_URL

class QualtricsLayout extends React.Component {
    constructor() {
        super()

        this.state = {
            data: null,
            error: false
        }
    }

    async componentDidMount() {
        const { 
            match: { params },
            location: { search }
        } = this.props

        // Get the event URL from pathname
        const path = params.formId

        // URL Params (FirstName, LastName, EmailAddress, QID)
        const query = new URLSearchParams(search)
        
        let url = await fetch('/url', { method: 'POST'}).then(res => res.text())
        
        let firstName = query.get('FirstName')
        let lastName = query.get('LastName')
        let email = query.get('EmailAddress')
        let QID = query.get('QID')

        if (!path) {
            this.setState({
                error: true
            })
            return
        }
        
        fetch(`${url}/api/v1/event/signup/${path}`).then(results => {
            // catch all server errors
            if (results.status !== 200) {
                this.setState({
                    error: true
                })

                return
            }
            
            return results.json()
        }).then(json => {
            let fields = []

            _.each(json.fields, function(field) {
                if (field.question_type === "firstname") {
                    field.question_values = firstName
                }

                if (field.question_type === "lastname") {
                    field.question_values = lastName
                }

                if (field.question_type === "email") {
                    field.question_values = email
                }

                fields.push(field)
            })

            json.QID = QID
            json.fields = fields

            this.setState({
                data: json
            })
            console.log(json)
        }).catch(error => {
            this.setState({
                error: true
            })
            console.log(properties.error, error)
        })
        
    }

    render() {
        // Error fetching data
        if (this.state.error) {
            return (
                <Container id="qualtrics-wrapper">
                    <Row>
                        <Col id="logo-wrapper">
                            <img src={Logo} alt={properties.qualtrics.logo}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alert variant='danger' className="text-center">
                                {properties.error}
                            </Alert>
                            <div className="text-center">
                                <button variant="primary" onClick={() => { this.componentDidMount() }}>{properties.retry}</button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            )
        }

        // Initial page load, data is still being fetched
        if (!this.state.data) {
            return (
                <Container id="qualtrics-wrapper">
                    <Row>
                        <Col id="logo-wrapper">
                            <img src={Logo} alt={properties.qualtrics.logo}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alert variant='light' className="text-center">
                                <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                <span className="ml-2">{properties.loading}</span>
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            )
        }

        // Data fetch was successful
        if (this.state.data) {
            let data = this.state.data

            return (
                <Container id="qualtrics-wrapper">
                    <Row>
                        <Col id="logo-wrapper">
                            <img src={Logo} alt={properties.qualtrics.logo}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p style={{ fontSize: '0.9em' }}>
                                Please submit your resume here and upload it in PDF format. Please use the following naming convention.
                                <br/><br/>
                                LastName_FirstName_Resume.pdf
                                <br/>
                                Example: Willis_Jeffrey_Resume.pdf
                            </p>
                        </Col>
                    </Row>
                    <Row id="form-wrapper" style={{ paddingTop: '2em' }}>
                        <Col>
                            <QualtricsForm data={data} fields={data.fields} url={data.enrollment_url}/>
                        </Col>
                    </Row>
                </Container>
            )
        }
    }
}

export default QualtricsLayout