import React from 'react'

import QuestionText from './question-text.js'
import properties from '../properties.js'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import * as Yup from 'yup'

import _ from 'underscore'


/**
 * Renders a select field using question values as options
 *
 * @class Select
 * @extends {React.Component}
 */
class Select extends React.Component {
    constructor(props) {
        super(props)

        // In https://jira.som.yale.edu/browse/DESK-141999 we add support for dynamically requiring another field when a certain value is selected
        // Here we hide the target field by default and set it to not required by default
        const field = this.props.field
        const setRequiredIfValueEquals = field.options?.action?.type === 'set_required_if_value_equals' ? field.options.action : null
        const targetField = !!setRequiredIfValueEquals ? this.props.fields.find(field => field.id === setRequiredIfValueEquals.target_field_id) : null
        
        if (targetField) {
            targetField.is_hidden = true
            targetField.is_required = false
        }
    }

    render() {
        const props = this.props
        const field = props.field
        let values = field.question_values

        // A regular drop down has values in an array
        // A guest drop down has values in a newline delimited string
        values = typeof values == "string" ? values.split('\n') : values

        // In https://jira.som.yale.edu/browse/DESK-141999 we add support for dynamically requiring another field when a certain value is selected
        // In our default case, the value is 'other' and must be a case-insensitive match
        // We only support this for fields where question_type is "dropdown"
        const setRequiredIfValueEquals = field.options?.action?.type === 'set_required_if_value_equals' ? field.options.action : null
        const targetField = !!setRequiredIfValueEquals ? this.props.fields.find(field => field.id === setRequiredIfValueEquals.target_field_id) : null

        return (
            <Form.Group as={Row} controlId={field.id}>
                <QuestionText field={field} column={true} sm={3}/>
                <Col sm="9">
                    <Form.Control
                        as="select"
                        className={props.className}
                        name={field.id}
                        onChange={e => {
                            const value = e.target.value

                            // dynamically set required for another field
                            if (!!setRequiredIfValueEquals && value.toLowerCase() === setRequiredIfValueEquals.value.toLowerCase()) {
                                let validationSchemaFields = this.props.validationSchema.fields

                                validationSchemaFields[parseInt(setRequiredIfValueEquals.target_field_id)] = Yup.string().required(properties.requiredField)

                                this.props.setValidationSchema(Yup.object().shape(validationSchemaFields))

                                if (targetField) {
                                    targetField.is_hidden = false
                                    targetField.is_required = true
                                }
                            }

                            // dynamically remove required for another field
                            if (!!setRequiredIfValueEquals && value.toLowerCase() !== setRequiredIfValueEquals.value.toLowerCase()) {
                                let validationSchemaFields = this.props.validationSchema.fields

                                delete validationSchemaFields[parseInt(setRequiredIfValueEquals.target_field_id)]

                                this.props.setValidationSchema(Yup.object().shape(validationSchemaFields))

                                if (targetField) {
                                    targetField.is_hidden = true
                                    targetField.is_required = false
                                }
                            }

                            props.onChange(e)
                        }}
                        onBlur={props.onBlur}
                        isValid={props.touched[field.id] && !props.errors[field.id]}
                        isInvalid={props.touched[field.id] && !!props.errors[field.id]}
                    >
                        <option value="">{properties.select}</option>
                        {values.map((value, key) => {
                            return (
                                <option value={value} key={field.id + key}>
                                    {value}
                                </option>
                            )
                        })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        {props.errors[field.id]}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
        )
    }    
}

export default Select